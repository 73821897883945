import AWS from "aws-sdk";
// import { Buffer } from "buffer"; // Import Buffer from the buffer module

const uploadImageToAws = async (image) => {
  const bucket = new AWS.S3({
    accessKeyId: "AKIA2YN3FSKDUOE2ONGA",
    secretAccessKey: "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",
    region: "us-east-1",
  });

  const params = {
    Bucket: "askyapp",
    Key:
      "upload/" +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      ".jpeg",
    Body: image,
    ContentType: image.type,
    ACL: "public-read",
  };

  try {
    const uploadedImage = await bucket.upload(params).promise();
    return uploadedImage.Location;
  } catch (error) {
    console.log(error);
  }
};

export default uploadImageToAws;
