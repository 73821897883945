("use Strict");
import React, { useEffect, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { Button } from "react-bootstrap";
import { rulesData, updateMasterData } from "./../../services/api";
import { toast } from "react-toastify";
const Rules = () => {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const [postCount, setPostCount] = useState(0);
  const [reactCount, setReactCount] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [loginCount, setLoginCount] = useState(0);
  const [spinLimit, setSpinLimit] = useState(0);

  const [subscriptionPost, setSubscriptionPost] = useState(0);
  const [subscriptionReact, setSubscriptionReact] = useState(0);
  const [subscriptionComment, setSubscriptionComment] = useState(0);
  const [subscriptionLogin, setSubscriptionLogin] = useState(0);
  const [spinLimitSubs, setSpinLimitSubs] = useState(0);

  const [pointsPost, setPointsPost] = useState(0);
  const [pointsReact, setPointsReact] = useState(0);
  const [pointsLogin, setPointsLogin] = useState(0);
  const [pointsComment, setPointsComment] = useState(0);
  const [pointsRefer, setPointsRefer] = useState(0);

  const [prizeFirst, setPrizeFirst] = useState(0);
  const [prizeSecond, setPrizeSecond] = useState(0);
  const [prizeThird, setPrizeThird] = useState(0);
  const [prizeRes, setPrizeRes] = useState(0);

  // State to store _id arrays
  const [freeIds, setFreeIds] = useState([]);
  const [subscriptionIds, setSubscriptionIds] = useState([]);
  const [pointsIds, setPointsIds] = useState([]);
  const [winningPriceIds, setWinningPriceIds] = useState([]);
  const [referIds, setReferIds] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setProgress(30);
        const response = await rulesData();
        const data = response?.result?.Free || [];
        const data2 = response?.result?.Silver || [];
        const data3 = response?.result?.WinningPrice || [];
        const data4 = response?.result?.Point || [];
        const data5 = response?.result?.Referral || [];

        // Store IDs in state
        setFreeIds(data.map((item) => item._id));
        setSubscriptionIds(data2.map((item) => item._id));
        setPointsIds(data4.map((item) => item._id));
        setWinningPriceIds(data3.map((item) => item._id));
        setReferIds(data5.map((item) => item._id));

        if (data.length > 0) {
          setPostCount(data[0]?.labelValue || 0);
          setReactCount(data[1]?.labelValue || 0);
          setCommentCount(data[2]?.labelValue || 0);
          setLoginCount(data[3]?.labelValue || 0);
          setSpinLimit(data[4]?.labelValue || 0);
        }

        if (data2.length > 0) {
          setSubscriptionPost(data2[0]?.labelValue || 0);
          setSubscriptionReact(data2[1]?.labelValue || 0);
          setSubscriptionComment(data2[2]?.labelValue || 0);
          setSubscriptionLogin(data2[3]?.labelValue || 0);
          setSpinLimitSubs(data2[4]?.labelValue || 0);
        }

        if (data4.length > 0) {
          setPointsPost(data4[0]?.labelValue || 0);
          setPointsReact(data4[1]?.labelValue || 0);
          setPointsLogin(data4[2]?.labelValue || 0);
          setPointsComment(data4[3]?.labelValue || 0);
          setPointsRefer(data5[0]?.labelValue || 0);
        }

        if (data3.length > 0) {
          setPrizeFirst(data3[0]?.labelValue || 0);
          setPrizeSecond(data3[1]?.labelValue || 0);
          setPrizeThird(data3[2]?.labelValue || 0);
          setPrizeRes(data3[3]?.labelValue || 0);
        }
        setProgress(100);
      } catch (error) {
        setProgress(100);
        console.error("Error fetching rules data:", error);
      }
    };

    fetchData();
  }, []);

  const updateRulesData = async () => {
    try {
      setLoading(true);
      setProgress(30);
      const prepareLabelValues = (labelType, values, ids) => {
        return values.map((item, index) => ({
          _id: ids[index] || null,
          labelType,
          labelName: item.labelName,
          labelValue: item.labelValue ?? 0,
          delete: false,
        }));
      };

      const data = {
        payload: [
          ...prepareLabelValues(
            "Free",
            [
              { labelValue: postCount, labelName: "Post" },
              { labelValue: reactCount, labelName: "React" },
              { labelValue: commentCount, labelName: "Comment" },
              { labelValue: loginCount, labelName: "Daily Login" },
              { labelValue: spinLimit, labelName: "Spin Limit" },
            ],
            freeIds
          ),
          ...prepareLabelValues(
            "Subscription",
            [
              { labelValue: subscriptionPost, labelName: "Post" },
              { labelValue: subscriptionReact, labelName: "React" },
              { labelValue: subscriptionComment, labelName: "Comment" },
              { labelValue: subscriptionLogin, labelName: "Daily Login" },
              { labelValue: spinLimitSubs, labelName: "Spin Limit" },
            ],
            subscriptionIds
          ),
          ...prepareLabelValues(
            "Points",

            [
              { labelValue: pointsPost, labelName: "OnCreate Post" },
              { labelValue: pointsReact, labelName: "React" },
              { labelValue: pointsLogin, labelName: "Daily Login" },
              { labelValue: pointsComment, labelName: "Comment" },
            ],
            pointsIds
          ),

          ...prepareLabelValues(
            "Referral",
            [{ labelValue: pointsRefer, labelName: "Points" }],
            referIds
          ),
          ...prepareLabelValues(
            "Winning Price",
            [
              { labelValue: prizeFirst, labelName: "1st" },
              { labelValue: prizeSecond, labelName: "2nd" },
              { labelValue: prizeThird, labelName: "3rd" },
              { labelValue: prizeRes, labelName: "Res" },
            ],
            winningPriceIds
          ),
        ],
      };

      const response = await updateMasterData(data);

      if (response.code === 200) {
        toast.success(response.message, {
          autoClose: 3000,
          toastId: "success",
        });
        console.log("Rules data updated successfully");
        console.log("Response:", response.payload);
        setLoading(false);
        setProgress(100);
      } else {
        toast.error(response.message, {
          autoClose: 3000,
          toastId: "error",
        });
        console.error("Error updating rules data:", response.error);
        setProgress(100);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating rules data:", error);
      setProgress(100);
      setLoading(false);
    }
  };

  return (
    <>
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />
      <div className="main-wrapper">
        <div className="dashboard">
          <section className="content pt-3">
            <div className="container-fluid">
              <div className="new-graph px-2">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="pl-3 mb-4">Rules & FAQs</h4>
                      <Button onClick={updateRulesData} disabled={loading}>
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </div>
                  </div>
                  <hr />

                  <div className="row mx-2 cards">
                    <div className="col-md-6 col-lg-6 mb-4">
                      <div className="card-body d-flex flex-column justify-content-between inset-outer-shadows bg-white card-custom-height">
                        <h2> 👤 User Limit (Free)</h2>

                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-0">React</p>
                          <input
                            type="number"
                            value={loginCount}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                /^\d{0,4}$/.test(value) &&
                                Number(value) <= 1000
                              ) {
                                setLoginCount(Number(value));
                              }
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "." ||
                                e.key === "-" ||
                                e.key === "e"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            className="form-control text-center"
                            style={{
                              width: "100px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "transparent",
                            }}
                            step="1"
                          />
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-0">Comment</p>
                          <input
                            type="number"
                            value={postCount}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                /^\d{0,4}$/.test(value) &&
                                Number(value) <= 1000
                              ) {
                                setPostCount(Number(value));
                              }
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "." ||
                                e.key === "-" ||
                                e.key === "e"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            step="1"
                            className="form-control text-center"
                            style={{
                              width: "100px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "transparent",
                            }}
                          />
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-0">Daily Login</p>
                          <input
                            type="number"
                            value={reactCount}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                /^\d{0,4}$/.test(value) &&
                                Number(value) <= 1000
                              ) {
                                setReactCount(Number(value));
                              }
                            }}
                            className="form-control text-center"
                            style={{
                              width: "100px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "transparent",
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "." ||
                                e.key === "-" ||
                                e.key === "e"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            step="1"
                          />
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-0">Spin Limit</p>
                          <input
                            type="number"
                            value={spinLimit}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                /^\d{0,4}$/.test(value) &&
                                Number(value) <= 1000
                              ) {
                                setSpinLimit(Number(value));
                              }
                            }}
                            className="form-control text-center"
                            style={{
                              width: "100px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "transparent",
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "." ||
                                e.key === "-" ||
                                e.key === "e"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            step="1"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6 mb-4">
                      <div className="card-body d-flex flex-column justify-content-between inset-outer-shadows bg-white card-custom-height">
                        <h2> 👤 User Limit (Subscription)</h2>

                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-0">Post</p>
                          <input
                            type="number"
                            value={subscriptionPost}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                /^\d{0,4}$/.test(value) &&
                                Number(value) <= 1000
                              ) {
                                setSubscriptionPost(Number(value));
                              }
                            }}
                            className="form-control text-center"
                            style={{
                              width: "100px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "transparent",
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "." ||
                                e.key === "-" ||
                                e.key === "e"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            step="1"
                          />
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-0">Daily Login</p>
                          <input
                            type="number"
                            value={subscriptionComment}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                /^\d{0,4}$/.test(value) &&
                                Number(value) <= 1000
                              ) {
                                setSubscriptionComment(Number(value));
                              }
                            }}
                            className="form-control text-center"
                            style={{
                              width: "100px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "transparent",
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "." ||
                                e.key === "-" ||
                                e.key === "e"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            step="1"
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-0">React</p>
                          <input
                            type="number"
                            value={subscriptionLogin}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                /^\d{0,4}$/.test(value) &&
                                Number(value) <= 1000
                              ) {
                                setSubscriptionLogin(Number(value));
                              }
                            }}
                            className="form-control text-center"
                            style={{
                              width: "100px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "transparent",
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "." ||
                                e.key === "-" ||
                                e.key === "e"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            step="1"
                          />
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-0">Comment</p>
                          <input
                            type="number"
                            value={subscriptionReact}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                /^\d{0,4}$/.test(value) &&
                                Number(value) <= 1000
                              ) {
                                setSubscriptionReact(Number(value));
                              }
                            }}
                            className="form-control text-center"
                            style={{
                              width: "100px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "transparent",
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "." ||
                                e.key === "-" ||
                                e.key === "e"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            step="1"
                          />
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-0">Spin Limit</p>
                          <input
                            type="number"
                            value={spinLimitSubs}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                /^\d{0,4}$/.test(value) &&
                                Number(value) <= 1000
                              ) {
                                setSpinLimitSubs(Number(value));
                              }
                            }}
                            className="form-control text-center"
                            style={{
                              width: "100px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "transparent",
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "." ||
                                e.key === "-" ||
                                e.key === "e"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            step="1"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6 mb-4">
                      <div className="card-body d-flex flex-column justify-content-between inset-outer-shadows bg-white card-custom-height">
                        <h2>🪙 Points</h2>

                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-0">OnCreate Post</p>
                          <input
                            type="number"
                            value={pointsPost}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                /^\d{0,4}$/.test(value) &&
                                Number(value) <= 1000
                              ) {
                                setPointsPost(Number(value));
                              }
                            }}
                            className="form-control text-center"
                            style={{
                              width: "100px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "transparent",
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "." ||
                                e.key === "-" ||
                                e.key === "e"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            step="1"
                          />
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-0">React</p>
                          <input
                            type="number"
                            value={pointsReact}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                /^\d{0,4}$/.test(value) &&
                                Number(value) <= 1000
                              ) {
                                setPointsReact(Number(value));
                              }
                            }}
                            className="form-control text-center"
                            style={{
                              width: "100px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "transparent",
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "." ||
                                e.key === "-" ||
                                e.key === "e"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            step="1"
                          />
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-0">Daily Login</p>
                          <input
                            type="number"
                            value={pointsLogin}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                /^\d{0,4}$/.test(value) &&
                                Number(value) <= 1000
                              ) {
                                setPointsLogin(Number(value));
                              }
                            }}
                            className="form-control text-center"
                            style={{
                              width: "100px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "transparent",
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "." ||
                                e.key === "-" ||
                                e.key === "e"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            step="1"
                          />
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-0">Comment</p>
                          <input
                            type="number"
                            value={pointsComment}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                /^\d{0,4}$/.test(value) &&
                                Number(value) <= 1000
                              ) {
                                setPointsComment(Number(value));
                              }
                            }}
                            className="form-control text-center"
                            style={{
                              width: "100px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "transparent",
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "." ||
                                e.key === "-" ||
                                e.key === "e"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            step="1"
                          />
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-0">Referral</p>
                          <input
                            type="number"
                            value={pointsRefer}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                /^\d{0,4}$/.test(value) &&
                                Number(value) <= 1000
                              ) {
                                setPointsRefer(Number(value));
                              }
                            }}
                            className="form-control text-center"
                            style={{
                              width: "100px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "transparent",
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "." ||
                                e.key === "-" ||
                                e.key === "e"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            step="1"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6 mb-4">
                      <div className="card-body d-flex flex-column justify-content-between inset-outer-shadows bg-white card-custom-height">
                        <h2>🏆 Winning Price</h2>

                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-0"> 1st</p>
                          <input
                            type="number"
                            value={prizeFirst}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                /^\d{0,4}$/.test(value) &&
                                Number(value) <= 1000
                              ) {
                                setPrizeFirst(Number(value));
                              }
                            }}
                            className="form-control text-center"
                            style={{
                              width: "100px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "transparent",
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "." ||
                                e.key === "-" ||
                                e.key === "e"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            step="1"
                          />
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-0">2nd</p>
                          <input
                            type="number"
                            value={prizeSecond}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                /^\d{0,4}$/.test(value) &&
                                Number(value) <= 1000
                              ) {
                                setPrizeSecond(Number(value));
                              }
                            }}
                            className="form-control text-center"
                            style={{
                              width: "100px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "transparent",
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "." ||
                                e.key === "-" ||
                                e.key === "e"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            step="1"
                          />
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-0">3rd</p>
                          <input
                            type="number"
                            value={prizeThird}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                /^\d{0,4}$/.test(value) &&
                                Number(value) <= 1000
                              ) {
                                setPrizeThird(Number(value));
                              }
                            }}
                            className="form-control text-center"
                            style={{
                              width: "100px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "transparent",
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "." ||
                                e.key === "-" ||
                                e.key === "e"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            step="1"
                          />
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-0">Res</p>
                          <input
                            type="number"
                            value={prizeRes}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                /^\d{0,4}$/.test(value) &&
                                Number(value) <= 1000
                              ) {
                                setPrizeRes(Number(value));
                              }
                            }}
                            className="form-control text-center"
                            style={{
                              width: "100px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "transparent",
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "." ||
                                e.key === "-" ||
                                e.key === "e"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            step="1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Rules;
