("use Strict");
import React, { useEffect, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import {
  leaderboardData,
  updateTransferData,
} from "../../services/api";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { startOfWeek, endOfWeek, format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef } from "react";
import uploadImageToAws from "./uploadImage";

const Lastweekwinner = () => {
  const [progress, setProgress] = useState(0);
  const [remainingParticipants, setRemainingParticipants] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [weekRange, setWeekRange] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedQrCode, setSelectedQrCode] = useState(null);
  const [showModal2, setShowModal2] = useState(false);
  const [showQRModal, setShowQRModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [SSImage, setSSImage] = useState(null);
  const [error, setError] = useState("");

  const handleWeekChange = (date) => {
    setStartDate(date);
    const startOfSelectedWeek = startOfWeek(date, { weekStartsOn: 1 });
    const endOfSelectedWeek = endOfWeek(date, { weekStartsOn: 1 });
    const formattedStart = format(startOfSelectedWeek, "yyyy-MM-dd");
    const formattedEnd = format(endOfSelectedWeek, "yyyy-MM-dd");

    const range = `${formattedStart} - ${formattedEnd}`;
    setWeekRange(range);
    fetchLeaderboardData(startOfSelectedWeek, endOfSelectedWeek);
  };

  const handleImageSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setPreviewImage(URL.createObjectURL(file));
      setError("");
    }
  };

  const handleCloseModal2 = () => {
    setShowModal2(false);
    setSelectedParticipant(null);
  };

  const handlePayNowClick = (participant) => {
    setSelectedParticipant(participant);
    setShowModal(true);
    setSelectedImage(null);
    setPreviewImage(null);
    setError("");
  };

  const fetchLeaderboardData = async (startOfWeek, endOfWeek) => {
    try {
      startOfWeek = format(startOfWeek, "yyyy-MM-dd");
      endOfWeek = format(endOfWeek, "yyyy-MM-dd");

      setProgress(30);
      const data = await leaderboardData(
        startOfWeek,
        endOfWeek
      );

      const leaderboardDatas = data.result.leaderboardData || [];
      setRemainingParticipants(leaderboardDatas.slice(0));
      setProgress(100);
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
      setProgress(0);
    }
  };
  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <button className={className} onClick={onClick} ref={ref}>
        {weekRange}
      </button>
    )
  );
  useEffect(() => {
    const startOfCurrentWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
    const endOfCurrentWeek = endOfWeek(new Date(), { weekStartsOn: 1 });

    setStartDate(startOfCurrentWeek);

    const formattedStart = format(startOfCurrentWeek, "yyyy-MM-dd");
    const formattedEnd = format(endOfCurrentWeek, "yyyy-MM-dd");
    const range = `${formattedStart} - ${formattedEnd}`;
    console.log("Here is the range",range);
    setWeekRange(range);

    fetchLeaderboardData(startOfCurrentWeek, endOfCurrentWeek);
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedParticipant(null);
    setSelectedImage(null);
    setPreviewImage(null);
    setError("");
  };

  const handlePayClick = async () => {
    if (!selectedImage) {
      setError("Please choose an image before uploading.");
      return;
    }
    setLoading(true);
    try {
      const imageUrl = await uploadImageToAws(selectedImage);
      if (!imageUrl) {
        setError("Failed to upload image.");
        setLoading(false);
        return;
      }
      if (
        (selectedParticipant && selectedParticipant.rewardId) ||
        (selectedQrCode && selectedQrCode?.rewardId)
      ) {
        const response = await updateTransferData({
          rewardId: selectedParticipant?.rewardId || selectedQrCode?.rewardId,
          paymentSS: imageUrl,
        });

        if (response.code === 200) {
          setShowModal(false);
          toast.success(response.message, {
            autoClose: 3000,
            toastId: "success",
          });
          const startOfSelectedWeek = startOfWeek(startDate, {
            weekStartsOn: 1,
          });
          const endOfSelectedWeek = endOfWeek(startDate, { weekStartsOn: 1 });

          const formattedStart = format(startOfSelectedWeek, "yyyy-MM-dd");
          const formattedEnd = format(endOfSelectedWeek, "yyyy-MM-dd");

          const data = await leaderboardData(
            formattedStart,
            formattedEnd
          );

          const leaderboardDatas = data.result.leaderboardData || [];
          setRemainingParticipants(leaderboardDatas.slice(0));
          setProgress(100);
          setShowModal(false);
          setShowModal2(false);
        }
      } else {
        toast.error("Reward Id is missing", {
          autoClose: 3000,
          toastId: "error",
        });
        console.error("Reward ID is missing");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setError("An error occurred while uploading the image.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />
      <div className="main-wrapper">
        <div className="dashboard">
          <section className="content pt-3">
            <div className="container-fluid">
              <div className="new-graph px-2">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="pl-3 mb-4">Leaderboard 🏆</h4>
                      <div>
                        <DatePicker
                          selected={startDate}
                          onChange={handleWeekChange}
                          dateFormat="I/R"
                          locale="en-GB"
                          className="form-control"
                          showWeekPicker
                          calendarStartDay={1}
                          customInput={
                            <ExampleCustomInput className="example-custom-input" />
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="col-12">
                    <table className="table table-striped">
                      <thead>
                        {remainingParticipants.length > 0 && (
                          <tr>
                            <th style={{ minWidth: "5px" }}>Rank</th>
                            <th style={{ minWidth: "150px" }}>Full Name</th>
                            <th style={{ minWidth: "150px" }}>Claim Request Name</th>
                            <th style={{ minWidth: "150px" }}>Cash Price</th>
                            <th style={{ minWidth: "150px" }}>Coins</th>
                            <th style={{ minWidth: "150px" }}>View QR Code</th>
                            <th>Payment Transfer</th>
                          </tr>
                        )}
                      </thead>
                      {remainingParticipants.length ? (
                        <tbody>
                          {remainingParticipants.map((participant, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={participant.profileImg}
                                  alt="Profile"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                    border: "1px solid blue",
                                    marginRight: "8px",
                                  }}
                                />
                                <span>{participant.fullName || "Anonymous"}</span>
                              </td>
                              <td>{participant.claimRequestName || "N/A"}</td>
                              <td>₹{participant.price || 0}</td>
                              <td>{participant.totalCoins || 0}</td>
                              <td>
                                {participant.qrCode ? (
                                  <button
                                    onClick={() => {
                                      setSelectedParticipant(participant);
                                      setShowModal2(true);
                                    }}
                                    style={{
                                      background:
                                        "linear-gradient(96deg, #204fa7 3.77%, #ff4343 75.47%)",
                                      color: "white",
                                      border: "none",
                                      padding: "8px 16px",
                                      cursor: "pointer",
                                      borderRadius: "20px",
                                    }}
                                  >
                                    View
                                  </button>
                                ) : (
                                  <span>N/A</span>
                                )}
                              </td>
                              <td>
                                {participant.isTransferred === true ? (
                                  <a 
                                  href="#" 
                                  style={{ color: "green", textDecoration: "none" }} 
                                  onClick={(e) => { 
                                    e.preventDefault(); 
                                    setSSImage(participant.paymentSS)// Prevent the default link behavior
                                    setShowQRModal(true); // Your custom function
                                  }}
                                >
                                  Transferred!
                                </a>
                                ) : (
                                  participant.qrCode ? 
                                  <button
                                    onClick={() =>
                                      handlePayNowClick(participant)
                                    }
                                    style={{
                                      background:
                                        "linear-gradient(96deg, #204fa7 3.77%, #ff4343 75.47%)",
                                      color: "white",
                                      border: "none",
                                      padding: "8px 16px",
                                      cursor: "pointer",
                                      borderRadius: "20px",
                                    }}
                                  >
                                    Upload ScreenShot
                                  </button> : "N/A"
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <div style={{ color: "red" }} className="text-center">
                          No Data Found!
                        </div>
                      )}
                    </table>
                    <Modal show={showModal} onHide={handleCloseModal} centered>
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ textAlign: "center", width: "100%" }}
                        >
                          Upload QR Code
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageSelect}
                            style={{ marginBottom: "15px" }}
                            className="form-control"
                          />
                          {error && (
                            <p style={{ color: "red", marginBottom: "10px" }}>
                              {error}
                            </p>
                          )}
                          {previewImage && (
                            <img
                              src={previewImage}
                              alt="Selected"
                              style={{
                                width: "250px",
                                height: "250px",
                                objectFit: "cover",
                                borderRadius: "10px",
                              }}
                            />
                          )}
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          onClick={handlePayClick}
                          style={{
                            background: "#28a745",
                            color: "white",
                            border: "none",
                            padding: "8px 25px",
                            cursor: "pointer",
                            borderRadius: "20px",
                          }}
                          disabled={loading}
                        >
                          {loading ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Transfer"
                          )}
                        </button>
                      </Modal.Footer>
                    </Modal>

                    <Modal
                      show={showModal2}
                      onHide={handleCloseModal2}
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ textAlign: "center", width: "100%" }}
                        >
                          QR Code
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {selectedParticipant && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {selectedParticipant.qrCode ? (
                              <img
                                src={selectedParticipant.qrCode}
                                alt="QR Code"
                                style={{
                                  width: "250px",
                                  height: "250px",
                                  objectFit: "cover",
                                  borderRadius: "10px",
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  width: "150px",
                                  height: "150px",
                                  backgroundColor: "#f0f0f0",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: "#ccc",
                                  fontSize: "12px",
                                  borderRadius: "10px",
                                }}
                              >
                                No QR Code
                              </div>
                            )}
                          </div>
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          onClick={handleCloseModal2}
                          style={{
                            background: "gray",
                            color: "white",
                            border: "none",
                            padding: "8px 25px",
                            cursor: "pointer",
                            borderRadius: "20px",
                          }}
                        >
                          {loading ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Close"
                          )}
                        </button>
                      </Modal.Footer>
                    </Modal>

                    <Modal
                      show={showModal2}
                      onHide={handleCloseModal2}
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ textAlign: "center", width: "100%" }}
                        >
                          QR Code
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {selectedParticipant && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {selectedParticipant.qrCode ? (
                              <img
                                src={selectedParticipant.qrCode}
                                alt="QR Code"
                                style={{
                                  width: "250px",
                                  height: "250px",
                                  objectFit: "cover",
                                  borderRadius: "10px",
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  width: "150px",
                                  height: "150px",
                                  backgroundColor: "#f0f0f0",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: "#ccc",
                                  fontSize: "12px",
                                  borderRadius: "10px",
                                }}
                              >
                                No QR Code
                              </div>
                            )}
                          </div>
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          onClick={handleCloseModal2}
                          style={{
                            background: "gray",
                            color: "white",
                            border: "none",
                            padding: "8px 25px",
                            cursor: "pointer",
                            borderRadius: "20px",
                          }}
                        >
                          {loading ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Close"
                          )}
                        </button>
                      </Modal.Footer>
                    </Modal>


                    <Modal
                      show={showQRModal}
                      onHide={handleCloseModal2}
                      centered
                    >
                      <Modal.Header >
                        <Modal.Title
                          style={{ textAlign: "center", width: "100%" }}
                        >
                          QR Code
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {SSImage && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {SSImage ? (
                              <img
                                src={SSImage}
                                alt="QR Code"
                                style={{
                                  width: "250px",
                                  height: "250px",
                                  objectFit: "cover",
                                  borderRadius: "10px",
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  width: "150px",
                                  height: "150px",
                                  backgroundColor: "#f0f0f0",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: "#ccc",
                                  fontSize: "12px",
                                  borderRadius: "10px",
                                }}
                              >
                                No Screenshot Attached!
                              </div>
                            )}
                          </div>
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          onClick={()=>setShowQRModal(false)}
                          style={{
                            background: "green",
                            color: "white",
                            border: "none",
                            padding: "8px 25px",
                            cursor: "pointer",
                            borderRadius: "20px",
                          }}
                        >
                            Close
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Lastweekwinner;
